import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme';

export const useStyles = makeStyles({
  subheader: {
    margin: '32px 0px 24px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  sectionHeader: {
    padding: '12px 12px 12px 16px',
    backgroundColor: themeColors.greyscale4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    marginBottom: 16,
  },
  container: {
    marginTop: '10px',
    marginLeft: '-8px',
    marginRight: '-8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  progress: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    overflowX: 'auto',
    marginTop: 16,
    paddingBottom: 25,
    minHeight: 615,

    '&::-webkit-scrollbar': {
      height: '10px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 1320,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 17,
    boxShadow: themeShadows.shadowSmall,
    padding: '5px 0px',
    width: '100%',
  },
  cancel: {
    paddingRight: 10,
  },
  save: {
    paddingLeft: 10,
    paddingRight: 10,
    borderLeft: `1px solid ${themeColors.greyscale3}`,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  modalFooter: {
    borderTop: `2px solid ${themeColors.greyscale4}`,
    display: 'flex',
    justifyContent: 'end',
    margin: '20px -24px 0px -24px',
    padding: '24px 24px 5px 24px',
  },
  loadingWrapper: {
    position: 'absolute',
    inset: 0,
    backgroundColor: '#ffffff7a',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
