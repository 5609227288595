/* eslint-disable no-magic-numbers */
import { IQueryParams } from 'app/shared/services';
import { StatusCodes } from 'http-status-codes';

export interface IClient {
  logo: string;
  name: string;
}

export enum Role {
  OPERATION_MANAGER = 'Operation Manager',
  BATCH_PLANT_OPERATOR = 'Batch Plant Operator',
  QUALITY_MANAGER = 'Quality Manager',
  PLANT_MANAGER = 'Plant Manager',
  CONTRACTOR = 'Contractor',
  QUALITY_TECHNICIAN = 'Quality Technician',
  READYMIX_SALES = 'Readymix Sales',
  MASTER_BUILDERS_SALES = 'Master Builders Solutions Sales',
  NONE = '',
}

export interface IRole {
  roleId: string;
  roleName: string;
  description?: string;
  active?: boolean;
  tenantId?: string;
}

export interface IUser {
  userId: string;
  firstName: string;
  lastName: string;
  email?: string;
  username?: string;
  referenceRoleName?: string;
  phoneNumber?: string;
  active?: boolean;
}

export interface INotificationEntityCard extends INotificationEntity {
  notificationEntitySummaryModel: {
    assignedUsersCount: number;
    currentUserAssigned: boolean;
    hasActiveNotifications: boolean;
  };
}

export interface IPourEventCard extends INotificationEntityCard {
  mixDesigns: {
    externalProductId: string;
    externalProductName: string;
    notificationEntityBaseModel: {
      internalId: string;
      externalId: string;
      name: string;
      notificationEntityGroupId: number;
      notificationEntityGroupName: string;
      lastTicketDispatchDateTimeUtc: string;
    } | null;
  }[];
}

export interface IProjectMember {
  firstName: string;
  lastName: string;
  roleName: string;
}
export interface IProject {
  clientRefId?: string;
  customerName: string;
  createdOnUtc: Date | string;
  projectId: string;
  hasActiveNotifications: boolean;
  externalProjectId: string;
  externalProjectName: string;
  externalProjectGID: string;
  projectStatus: ProjectStatus;
  assignedUsersCount: number;
  currentUserAssigned: boolean;
  description: string;
  plants: IPlant[];
  notificationEntityBaseModel: INotificationEntity | null;
  lastTicketDispatchDateTimeUtc: string | null;
}
export interface IOwnProject {
  projectId: string;
  externalProjectId: string;
  projectName: string;
  clientTitle: string;
  active: boolean;
  usersCount: number;
}

export enum NotificationEntityStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
  UPCOMING = 'Upcoming',
}

export enum PourEventStatus {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export enum ProjectStatus {
  ACTIVE = 'Active',
  UPCOMING = 'Upcoming',
}

export enum OrganizationDataFlowStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface IMixDesignData {
  externalProductId: string;
  externalProductName: string;
}

export interface IPourEvent {
  pourEventId: string;
  externalProductId: string;
  externalProductName: string;
  pourEventName: string;
  dispatchDate: string;
  address: string;
  city: string;
  state: string;
  name?: string;
  dispatchId: string;
  plants: IPlant[];
  active: boolean;
  isCompleted: boolean;
  createdBy: string;
  updatedBy: string;
  createdOnUtc: string | Date;
  updatedOnUtc: string | Date;
  mixDesigns: IMixDesignData[];
  notificationEntityBaseModel: INotificationEntity | null;
}

export interface IMixDesign {
  mixDesign: IMixDesignData;
  details: {
    mixDesignDetailId: string;
    externalProductId: string;
    externalProductName: string;
    itemId: string;
    itemType: string;
    itemDescription: string;
    itemTargetQuantity: number;
    itemTargetQuantityUom: string;
    maxWaterToAdd: number;
    maxWaterToAddUom: string;
    active: boolean;
    supplierId: string;
  }[];
}

export enum PourEventStatus {
  DEPARTED = 'Departed',
  ARRIVED = 'Arrived',
  UNLOADED = 'Unloaded',
}

export interface IBatchEvent {
  crn: string;
  batchAge: number;
  historyRecordName: string;
  slumpRangeMin: number;
  slumpRangeMax: number;
  vehicleId: string;
  ticketNumber: string;
  timestamp: string | Date;
  loadNumber: string;
  totalOrdered: IProbeValue;
  totalTicketed: IProbeValue;
  workability: IProbeValue;
  probeTemperature: IProbeValue;
  revolutions: string;
  waterAllowed: IProbeValue;
  waterAdded: IProbeValue;
  pressure: IProbeValue;
}

export interface IProjectInformation {
  projectId: string;
  clientName?: string;
  clientContactName?: string;
  title?: string;
  email?: string;
  phoneNumber?: string;
  generalContractor?: string;
  architect?: string;
  engineerOfRecord?: string;
  finisher?: string;
  subContractor?: string;
  notes?: string;
}

//Real interfaces

export interface IResponseData<T> {
  data: T;
  status?: number;
  messages?: string[];
  metadata?: {
    totalCount: number;
    skip: number;
    take: number;
  };
}

export interface IMetaData {
  take: number;
  skip: number;
  totalCount: number;
}

export enum NotificationEntityType {
  ORGANIZATION,
  PLANT,
  CUSTOMER,
  MIX_DESIGN,
  VEHICLE,
  DRIVER,
  PROJECT,
  POUR_EVENT,
}

export interface IApiParams {
  queryParameters?: IQueryParams;
  organizationId?: string;
  entityType?: NotificationEntityType;
  isInfiniteScrolling?: boolean;
  hasCustomAssignments?: boolean;
}

export interface IProfile {
  userId: string;
  firstName: string;
  lastName: string;
  email?: string;
  username?: string;
  referenceRoleName?: string;
  roleNames?: string[];
  phoneNumber?: string;
  active?: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  referenceRoleId?: string | null;
  notificationEmails?: string[];
}

export interface IProfileUpdate {
  userId: string;
  referenceRoleId: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export enum ClientProjectMemberRoles {
  CONTRACTOR = 'General Contractor',
  ARCHITECT = 'Architect',
  ENGINEER = 'Engineer of Record',
  FINISHER = 'Finisher',
  SUB_CONTRACTOR = 'Sub-contractor',
}

export interface IClientProjectMembers {
  clientProjectMemberId?: string;
  role: ClientProjectMemberRoles;
  name: string | null;
}

export interface ICreateProject {
  projectRef: string;
  clientRef: string;
  clientContactName: string;
  email: string;
  phoneNumber: string;
  title: string;
  clientProjectMembers: IClientProjectMembers[];
  description: string;
  projectId?: string;
}

export interface IProjectDetails {
  projectId: string;
  projectRefId: string;
  supplierId: string;
  clientId: string;
  externalProjectName: string;
  externalProjectId: string;
  description: string;
  customerName: string;
  clientContactName: string;
  clientEmail: string;
  clientPhone: string;
  clientTitle: string;
  active: boolean;
  clientProjectMembers: IClientProjectMembers[];
  projectStatus: number;
}

export interface IProjectMemberRequest {
  projectId?: string;
  userId: string;
  roleId?: string;
  plantGroupId?: number;
}

export interface IProjectMembers {
  userId: string;
  firstName: string;
  lastName: string;
  roleNames: string[];
}

export interface IOrganizationRoles {
  roleId: string;
  roleName: string;
  assignedUsers: IProfile[];
}

export enum UserB2cRoles {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export interface ITeammateInvitation {
  inviteEmail: string;
  isAdmin: boolean;
  roleId: string;
}

export interface IPourEventHistory {
  externalProductId: string;
  externalProductName: string;
  pourEventHistoryDetails: IHistoryRecord[];
}

export interface IAcknowledgement {
  userId: string;
  firstName: string;
  lastName: string;
  userEmail: string;
  acknowledgedDateTimeUtc: string;
  acknowledgementNote: string;
}

export interface INote {
  noteDataId: number;
  historyRecordId: number;
  userId: string;
  firstName: string;
  lastName: string;
  userEmail: string;
  noteText: string;
  createdOnUtc: string;
}

export enum HistoryType {
  EVENT,
  NOTIFICATION,
}

export enum LatestMeasurementProperty {
  SLUMP = 1,
  TEMP = 2,
  PRESSURE = 3,
  SLUMP_FLOW = 5,
  AIR_CONTENT = 6,
  DENSITY = 7,
  VOLUME = 4,
  SLUMP_METER = 8,
}
export interface ILatestMeasurementValue {
  propertyType: LatestMeasurementProperty;
  propertyName: string;
  propertyValue: IProbeValue;
}
export interface ILatestMeasurement {
  eventStatusCode: string;
  isManualMeasurement?: boolean;
  probeValues: ILatestMeasurementValue[];
}

export interface INotificationSent {
  createdOnUtc: Date | string;
  firstName: string;
  lastName: string;
  notificationDataId: number;
  taggedByUserId: string;
  userEmail: string;
  userId: string;
  notificationEntityBaseModel:
    | (INotificationEntity & { notificationEntityType: NotificationEntityType })
    | null;
}

export interface IHistoryRecord {
  historyType: HistoryType;
  historyRecordName: string;
  historyRecordId: string;
  eventStatusCode: string;
  projectId?: string;
  projectName?: string;
  customerName?: string;
  plantOriginDescription?: string;
  dispatchDateTimeUtc?: string | Date;
  historyRecordDescription: string;
  notificationTypeId: string;
  batchAge: number;
  eventDateTimeUtc: string | Date;
  probeTimestampUtc: string | Date;
  alertReferenceValue: number;
  delayDifferenceMinutes: number;
  notificationCategory: NotificationCategory | null;
  revolutions: IProbeValue;
  workability: IProbeValue;
  probeTemperature: IProbeValue;
  waterAddedCumulative: IProbeValue;
  pressure: IProbeValue;
  notificationRangeValue: number;
  acknowledgements: IAcknowledgement[];
  notes: INote[];
  isAcknowledged: boolean;
  canBeAcknowledged: boolean;
  latestMeasurements?: ILatestMeasurement[];
  externalProductId: string;
  externalProductName: string;
  slumpFlow?: IProbeValue;
  density?: IProbeValue;
  createdBy?: string;
  airContentPercentage?: number;
  isManualProbe?: boolean;
  manualProbeDataId?: number;
  notificationEntityBaseModel: INotificationEntity;
  notificationEntityId: string;
  notificationEntityType: NotificationEntityType;
  notifications: INotificationSent[];
  isInvalid?: boolean;
}

export interface ICompressiveStrengthMeasurements {
  measurement: string;
  compressiveStrength: {
    manualProbeDataId: number;
    isInvalid: boolean;
    hoursAfterPourEvent: number;
    value: number;
    createdBy?: string;
    createdOnUtc?: string;
  }[];
}

export interface IWeather {
  temperature: IProbeValue | null;
  windSpeed: IProbeValue | null;
  humidity: IProbeValue | null;
  precipitation: IProbeValue | null;
  eventStatusCode: string | null;
  measuredAtUtc: Date | null;
}

export interface IRouteTime {
  travelTime: IProbeValue;
  travelDistance: IProbeValue;
  trafficTime: IProbeValue;
  departureOnUtc: Date | null;
  arriveOnUtc: Date | null;
}

export interface IHistoryRecordExtended {
  slumpNotificationId?: string;
  vehicleId?: string;
  ticketNumber?: string;
  loadNumber?: number;
  originId?: string;
  originDescription?: string;
  totalTicketed: IProbeValue;
  totalOrdered: IProbeValue;
  targetSlump: IProbeValue;
  waterAllowed: IProbeValue;
  cumulativeVolume: number;
  slumpRange: {
    minValue: number;
    maxValue: number;
  };
  slumpFlow: IProbeValue;
  airContentPercentage: number;
  density: IProbeValue;
  latestSlump: IProbeValue;
  latestSlumpTimestampUtc: Date;
  latestTemperature: IProbeValue;
  latestTemperatureTimestampUtc: Date;
  externalProductId: string;
  externalProjectName?: string;
  externalProductName?: string;
  manualProbeDataId?: number;
  isManualProbe?: boolean;
  batchHistoryDetails: IHistoryRecord[];
  compressiveStrengthMeasurements: ICompressiveStrengthMeasurements;
  weather: IWeather | null;
  surfaceEvaporation: IProbeValue;
  city: string | null;
  state: string | null;
  country: string | null;
  airContent: number | null;
  latestAirContentTimestampUtc: Date | null;
  customerName?: string;
  customerId?: string;
  route: IRouteTime | null;
}

export interface IBatchMixProps {
  batchId: number;
  materialCode: string;
  description: string;
  targetQuantity: number;
  targetQuantityUom: string;
  batchedQuantity: number;
  batchedQuantityUom: string;
  moisturePercent: number;
  variancePercent: number;
}

export interface IRenamePourEvent {
  pourEventGid: string;
  newName: string;
}

export interface IError {
  statusCode?: StatusCodes | string;
  message?: string;
}

export interface ICreateOrganizationPayload {
  supplierId?: string;
  supplierName: string;
  description?: string;
  measurementSystem: string;
  active: boolean;
  logo?: File;
}

export interface IOrganization {
  supplierId: string;
  supplierName: string;
  description?: string;
  assignedAdminsCount: number;
  active: boolean;
}

export interface IOrganizationDetails {
  supplierName: string;
  description?: string;
  active: boolean;
  supplierImagePath: string;
  measurementSystem: number;
}

export interface INotification {
  notificationEntityType: NotificationEntityType;
  supplierNotificationId: string;
  notificationTypeId: string;
  active: boolean;
  minValue: number;
  maxValue: number;
  step: number;
  propertyValue: number;
  notificationText: string;
  description: string;
  notificationCode: string;
  isAssigned: boolean;
}

export interface IUserNotificationSettings {
  userId: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  roleNames: string[];
  notifications: INotification[];
}

export enum NotificationType {
  TEMPERATURE = 'temperature',
  SLUMP = 'slump',
  TRUCK = 'truck',
}

export interface IEnableNotification {
  notificationTypeId?: string;
  enable: boolean;
}

export interface IEnableNotificationAllGroup {
  enable: boolean;
  description?: string;
}

export interface IUpdateNotificationStatus {
  notificationTypeId?: string;
  active: boolean;
}

export interface IUpdateNotificationStatusAllGroup {
  active: boolean;
  description?: string;
}

export interface IUpdateNotificationStatusAllProject {
  projectId: string;
  active: boolean;
}

export interface IUpdateNotificationProperty {
  notificationTypeId?: string;
  propertyValue: boolean;
}

export interface IChangeUserNotificationStatusPayload {
  userId: string;
  isAssigned: boolean;
  notificationTypeId?: string;
}

export interface IProbeValue {
  measurement: string | null;
  value: number | null;
}

export enum NotificationCode {
  SLUMP_OUT_OF_RANGE = 'SlumpOutOfRange',
  SLUMP_OUT_OF_RANGE_AT_JOB = 'SlumpOutOfRangeAtJob',
  TEMP_OUT_OF_RANGE_MIN = 'TempOutOfRangeMin',
  TEMP_OUT_OF_RANGE_MAX = 'TempOutOfRangeMax',
  TRUCK_TIME_DELAY_AT_PLANT = 'TruckTimeDelayAtPlant',
  TRUCK_TIME_DELAY_LOADING = 'TruckTimeDelayLoading',
  TRUCK_TIME_DELAY_AT_JOB = 'TruckTimeDelayAtJob',
  TEMP_INCREASE_DURING_TRANSIT = 'TempIncreaseDuringTransit',
  TEMP_DECREASE_DURING_TRANSIT = 'TempDecreaseDuringTransit',
  TEMP_OVER_MAX = 'TempOverMax',
  TEMP_UNDER_MIN = 'TempUnderMin',
  SLUMP_INCREASE_DURING_TRANSIT = 'SlumpIncreaseDuringTransit',
  SLUMP_DECREASE_DURING_TRANSIT = 'SlumpDecreaseDuringTransit',
  SLUMP_INCREASE_OVER_PREV_BATCH = 'SlumpIncreaseOverPrevBatch',
  SLUMP_DECREASE_OVER_PREV_BATCH = 'SlumpDecreaseOverPrevBatch',
  TEMP_CHANGE_FROM_PREV_BATCH = 'TempChangeFromPrevBatch',
  REQUIRED_REVOLUTIONS = 'RequiredRevolutions',
  MULTIPLE_BATCHES_ADDING_WATER = 'MultipleBatchesAddingWater',
  BATCH_CEMENTITIOUS_TARGET_DEVIATION = 'BatchedCementitiousTargetDeviation',
  BATCH_AGGREGATE_TARGET_DEVIATION = 'BatchedAggregateTargetDeviation',
  BATCH_WATER_TARGET_DEVIATION = 'BatchedWaterTargetDeviation',
  BATCH_ADMIXTURE_TARGET_DEVIATION = 'BatchedAdmixtureTargetDeviation',
  RETURNED_CONCRETE = 'ReturnedConcrete',
  REJECTED_LOAD = 'RejectedLoad',
  AGGREGATE_MOISTURE_OUT_OF_RANGE_MIN = 'AggregateMoistureOutOfRangeMin',
  AGGREGATE_MOISTURE_OUT_OF_RANGE_MAX = 'AggregateMoistureOutOfRangeMax',
  SURFACE_EVAPORATION_RATE = 'SurfaceEvaporationRate',
}

export enum NotificationCategory {
  SLUMP = 'slump',
  TEMPERATURE = 'temperature',
  REVOLUTIONS = 'revolutions',
  TIME_DELAY = 'timeDelay',
  SLUMP_PREV_BATCH = 'slumpPrevPatch',
  TEMP_PREV_BATCH = 'tempPrevBatch',
  ADDING_WATER = 'addingWater',
  TARGET_DEVIATION = 'targetDeviation',
  RETURNED_CONCRETE = 'returnedConcrete',
  REJECTED_LOAD = 'rejectedLoad',
  MOISTURE = 'moisture',
  SURFACE_EVAPORATION_RATE = 'surfaceEvaporationRate',
}

export interface IErrorData {
  code: string;
  message: string;
}

export enum EventsEnum {
  signIn = 'Sign_In',
  pressEvent = 'Press_Event',
  pageView = 'Page_View',
}

export interface IPlantGroup {
  plantGroupId: number;
  plantGroupName: string;
  plants: IPlant[];
}

export interface INotificationEntity {
  internalId: string;
  externalId: string;
  name: string;
  notificationEntityGroupId: number;
  notificationEntityGroupName: string;
  lastTicketDispatchDateTimeUtc: string;
}

export interface IDosageEntity {
  dosageName: string;
  dosageId: string;
}

export interface IDosageProperty {
  dosagePropertyId: string;
  dosagePropertyName: string;
  dosagePropertyDescription: string;
  dosagePropertyRange: string;
}

export interface IAssignedDosages {
  dosageId: string;
  dosageName: string;
}

export interface IDosageEntityFull extends IDosageEntity {
  dosageNotificationEntities: {
    internalId: string;
    externalId: string;
    name: string;
    notificationEntityGroupId: number;
    notificationEntityGroupName: string;
    lastTicketDispatchDateTimeUtc: string;
  }[];
  dosagePropertyValues: {
    dosageTemperaturePropertyModel: IDosageProperty;
    dosageBatchAgePropertyModel: IDosageProperty;
    value: number;
  }[];
  dosageBatchItems: {
    itemId: string;
    itemType: string;
    itemDescription: string;
    rangeMin: number;
    rangeMax: number;
  }[];
}

export interface IBatchItem {
  itemId: string;
  itemType: string;
  itemDescription: string;
}

export interface IDosagePropertyEntity {
  dosagePropertyName: string;
  dosagePropertyId: string;
  dosagePropertyDescription: string;
  dosagePropertyRange: string;
  rangeMin: number;
  rangeMax: number;
}

export interface IDosagePayload {
  dosageName: string;
  notificationEntityId: string;
  notificationEntityType: number;
}

export interface IAssignDosagePayload {
  dosageId: string;
  notificationEntityId: string;
  notificationEntityType: number;
}

export interface IAddDosagePropertyValuePayload {
  dosageId: string;
  dosageTemperaturePropertyId: string;
  dosageBatchAgePropertyId: string;
  value: number;
}

export interface IAddDosageBatchItemPayload {
  itemId: string;
  itemType: string;
  itemDescription: string;
  rangeMin: number;
  rangeMax: number;
  dosageId: string;
}

export interface INotificationEntitiesPayload {
  query: string;
  take: number;
  lastElementDateTimeUtc: string;
  plantExternalIds?: string[];
  mixDesignExternalIds?: string[];
  vehicleExternalIds?: string[];
  driverExternalIds?: string[];
  customerExternalIds?: string[];
  pourEventGIDs?: string[];
  projectExternalIds?: string[];
}

export interface IRelatedEntitiesPayload {
  skip?: string;
  take: string;
  query?: string;
  sortBy?: string;
  sortAsc?: true;
  startDateTime: string;
  endDateTime: string;
  userId?: string;
  crossmatching?: boolean;
  lastElementDateTimeUtc?: string;
  ticketsWithStatusesOnly: boolean;
  mixDesignExternalIds: string[];
  plantExternalIds: string[];
  vehicleExternalIds: string[];
  driverExternalIds: string[];
  customerExternalIds: string[];
  pourEventGIDs: string[];
  projectExternalIds: string[];
}

export interface IAddToNotificationEntity {
  notificationEntityGroupId: number;
  internalIds: string[];
}

export interface INotificationEntityGroup {
  notificationEntityGroupId: number;
  notificationEntityGroupName: string;
  notificationEntityType: NotificationEntityType;
  notificationEntities?: INotificationEntity[];
}

export interface IPlant {
  plantOriginId: string;
  originId: string;
  plantName: string;
  enableNotifications: boolean;
  enableVisibility: boolean;
}

export interface IPlantTemperature {
  measurement: string;
  value: number | null;
}

export interface ICreateNotificationEntityGroupRequest {
  notificationEntityGroupName: string;
  internalIds: string[];
}

export interface IChangePlantNotificationStateRequest {
  enableNotifications: boolean;
  enableVisibility: boolean;
  plantOriginId: string;
}
export interface IPlantTemperatureRequest {
  measurement: string;
  value: number | null;
}

export interface IChangeVehicleNotificationStateRequest {
  enableNotifications: boolean;
  enableVisibility: boolean;
  vehicleId: string;
}

export interface IPlantGroupRequest {
  plantGroupId: number;
  plantOriginIds: string[];
}

export interface IUpdateNotificationEntityGroup {
  notificationEntityGroupId: number;
  notificationEntityGroupName: string;
}

export interface IUserToGroupRoleRequest {
  plantGroupId: number;
  roleId?: string;
  userId?: string;
  userRoles?: IProjectMemberRequest[];
}

export interface IAddUserToGroupRoleRequest {
  plantGroupId: number;
  userRoles: IProjectMemberRequest[];
}

export interface IAddUserToGroupResponse {
  userId: string;
  roleId: string;
  isSuccessful: boolean;
  message: string;
}

export enum NotificationOrigin {
  ORG_LEVEL,
  PLANT_LEVEL,
  PROJECT_LEVEL,
}

export interface IIcon {
  icon: any;
  action?: () => void;
}

export interface IVehicle {
  vehicleInternalId: string;
  vehicleId: string;
  vehicleDescription: string;
  enableNotifications: boolean;
  enableVisibility: boolean;
  hasProbeTracker: boolean;
}

export interface IMixId {
  externalProductId: string;
  externalProductName: string;
}

export interface ITeamAssignments {
  userId: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  roleNames: string[];
  notifications: {
    notificationCode: string;
    isAssigned: boolean;
    notificationTypeId: string;
    notificationEntityType: NotificationEntityType;
    hasOtherAssignments?: boolean;
  }[];
}

export interface IMixIdCategorization {
  mixDesignInternalId: string;
  externalProductId: string;
  externalProductName: string;
  residential: true;
  nonResidential: true;
  dotPublicInfrastructure: true;
  other: true;
  interior: true;
  exterior: true;
  horizontalOnGround: true;
  horizontalElevated: true;
  vertical: true;
  architectural: true;
  structural: true;
  pumpable: true;
  hardTrowelFinish: true;
  hesAge: number;
  hesStrength: number;
  d28Strength: number;
  workabilityMin: number;
  workabilityMax: number;
  frc: true;
  sustainable: true;
  valueAdded: true;
}

export interface IProbeRanges {
  slumpRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  slumpFlowRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  densityRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  concreteTemperatureRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  hydraulicPressureRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  compressiveStrRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  airContentPercentageRange: {
    minValue: number;
    maxValue: number;
    measurement: string;
  };
  maxAge: number;
  probeDays: number[];
  availableRanges:
    | {
        eventStatusCode: string;
        eventDateTimeUtc: string;
      }[]
    | null;
  defaultTimeSelectionUTC: string;
  maxTimeUTC: string | Date;
  minTimeUTC: string | Date;
}

export interface ICreateManualProbe {
  crn?: string;
  probeTakenOn?: string;
  eventStatusCode?: string;
  hoursAfterPourEvent?: number;
  slump?: {
    measurement?: string;
    value: number;
  };
  slumpFlow?: {
    measurement?: string;
    value: number;
  };
  airContentPercentage?: number;
  density?: {
    measurement?: string;
    value: number;
  };
  concreteTemperature?: {
    measurement?: string;
    value: number;
  };
  hydraulicPressure?: {
    measurement?: string;
    value: number;
  };
  compressiveStrength?: {
    measurement?: string;
    value: number;
  };
  noteText?: string;
  receiverIds?: string[];
}

export interface IChangeProbeValidity {
  manualProbeDataId: number;
  isInvalid: boolean;
}

export interface ISupplier {
  id: string;
  name: string;
}

export interface ITicketHealthCheck {
  ticketNumber: string;
  crn: string;
}

export interface IHealthCheckPayload {
  supplierId: string;
  startDate: Date | string;
  endDate: Date | string;
}

export interface IEventHealthCheck {
  crn: string;
  ticketNumber: string;
  eventStatuses: string[];
}

export enum HealthCheckStatus {
  NONE,
  INVALID_DATA,
  MISSING,
}

export interface IBatchesHealthCheck {
  crn: string;
  items: {
    id: string;
    status: HealthCheckStatus;
  }[];
}

export interface ISupplierReportRequest {
  minutes?: string;
  delayLessThanMinutes?: string;
}

export interface ISupplierReport {
  supplierName: string;
  totalTickets: number;
  totalEvents: number;
  providerDelayData: {
    avgDelayInMin?: number;
    maxDelayInMin?: number;
  };
  queueDelayData: {
    avgDelayInMin?: number;
    maxDelayInMin?: number;
  };
  status: number;
}

export interface IGetUserNotificationAssignmentsPayload {
  userId: string;
  notificationTypeId: string;
}

export interface IGetUserNotificationAssignmentsResponse {
  internalId: string;
  externalId: string;
  name: string;
  notificationEntityGroupId: number;
  notificationEntityGroupName: string;
  lastTicketDispatchDateTimeUtc: string;
  notificationEntityType: NotificationEntityType;
}

export interface IDeleteUserNotificationAssignmentsPayload {
  userId: string;
  notificationTypeId: string;
}
