import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  formControl: {
    width: 300,
    marginTop: 8,
  },
  selectContainer: {
    marginTop: 15,
  },
  labelContainer: {
    marginLeft: 10,
  },
  groupsContainer: {
    width: '100%',
    paddingTop: 20,
    display: 'flex',
    marginBottom: 20,
    minHeight: 30,
    justifyContent: 'flex-end',
    gap: 16,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainerGap: {
    gap: 24,
  },
  button: {
    width: 70,
    cursor: 'pointer',
  },
  wrapper: {
    marginTop: 80,
  },
  container: {
    marginTop: '-110px',
    minHeight: 100,
  },
  actionButtons: {
    minWidth: 'unset',
    padding: '4px 8px',
  },
  info: {
    marginRight: 3,
  },
});
