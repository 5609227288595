import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import {
  CellAlignment,
  CellSize,
  PaginationType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './GroupsSection.styles';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { RenameModal } from 'app/shared/components/generic-ui/RenameModal';
import { IAction } from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import {
  IAssignedDosages,
  IPlantTemperature,
  IResponseData,
  NotificationEntityType,
  Routes,
} from 'types';
import { firebaseAnalytics } from 'app/shared/analytics';
import { CustomTeamModal } from 'app/shared/components/generic-ui';
import {
  useNotificationEntityApi,
  useNotificationEntityGroupApi,
  usePlantApi,
} from 'app/shared/hooks';
import { useSearchParams } from '../hooks';
import { useAuth, useNotificationSettings } from 'app/shared/providers';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationPopover } from 'app/shared/components/generic-ui/NotificationSettings/NotificationItem/NotificationPopover';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as InfoIcon } from 'assets/info.svg';

const firstPage = 1;
const firstElement = 0;
const onePage = 1;
const minArrayLength = 1;

interface Props {
  addDosageHandler: () => void;
  isFetchingDosage: boolean;
  assignedDosages: IResponseData<IAssignedDosages[]> | undefined;
}

export const GroupsSection = ({
  addDosageHandler,
  isFetchingDosage,
  assignedDosages,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMember, isSuperAdmin } = useAuth();

  const {
    organizationId,
    tab,
    page,
    perPage,
    alternativePage,
    alternativePerPage,
  }: {
    organizationId: string;
    tab: string;
    page: string;
    perPage: string;
    alternativePage: string;
    alternativePerPage: string;
  } = useParams();
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isAddPlantModalOpen, setIsPlantModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [mixTemperature, setMixTemperature] = useState<IPlantTemperature>();
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const history = useHistory();

  const {
    changeSelectedEntityHandler,
    selectedEntity,
    entityType,
    resetSelectedEntityHandler,
    deleteGroupHandler,
  } = useNotificationSettings();

  const { searchQuery, searchParams } = useSearchParams();

  const groupQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.notificationEntityGroupId]);

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        query: searchQuery || '',
        skip: 0,
      },
    };
  }, [entityType, organizationId, searchQuery]);

  const queryPlantParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        notificationEntityId: selectedEntity?.internalId,
      },
    };
  }, [organizationId, selectedEntity?.internalId]);

  const deleteGroupsParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        notificationEntityGroupId:
          selectedEntity?.notificationEntityGroupId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.notificationEntityGroupId]);

  const {
    addNotificationEntitiesToGroup,
    updateNotificationEntityGroup,
    removeNotificationEntityFromGroup,
    createNotificationEntityGroup,
  } = useNotificationEntityGroupApi({
    organizationId: organizationId,
    entityType: entityType,
  });

  const {
    getPlantMixTemperatureResponse,
    fetchPlantMixTemperatureResponse,
    isFetchingPlantMixTemperatureResponse,
    changePlantMixTemperature,
  } = usePlantApi(queryPlantParams);

  const validationSchema = yup.object().shape({
    value: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        getPlantMixTemperatureResponse?.data.measurement === 'FAH' ? 100 : 40,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          getPlantMixTemperatureResponse?.data.measurement === 'FAH' ? 100 : 40
        }`,
      )
      .min(
        getPlantMixTemperatureResponse?.data.measurement === 'FAH' ? 0 : -20,
        `${t(translations.errors.minValue)} ${
          getPlantMixTemperatureResponse?.data.measurement === 'FAH' ? 0 : -20
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
  });

  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { value: null },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    fetchUnassignedNotificationEntities,
    isFetchingUnassignedNotificationEntities,
    fetchNotificationEntities,
    unassignedNotificationEntities,
  } = useNotificationEntityApi(queryParams);

  const { deleteNotificationEntityGroup } =
    useNotificationEntityGroupApi(deleteGroupsParams);

  const {
    fetchNotificationEntityGroup: fetchPlantGroup,
    notificationEntityGroup: plantGroup,
    isFetchingNotificationEntityGroup,
  } = useNotificationEntityGroupApi(groupQueryParams);

  useEffect(() => {
    if (isAddPlantModalOpen || isCreateGroupModalOpen) {
      fetchUnassignedNotificationEntities();
    }
  }, [
    fetchUnassignedNotificationEntities,
    isAddPlantModalOpen,
    isCreateGroupModalOpen,
    queryParams,
  ]);

  useEffect(() => {
    if (selectedEntity?.notificationEntityGroupId) {
      fetchPlantGroup();
    }
  }, [fetchPlantGroup, selectedEntity]);

  useEffect(() => {
    if (!isMember && entityType === NotificationEntityType.PLANT) {
      fetchPlantMixTemperatureResponse().then(
        getPlantMixTemperatureResponse => {
          setMixTemperature(getPlantMixTemperatureResponse.data?.data);
          setValue(
            'value',
            //@ts-ignore
            getPlantMixTemperatureResponse.data?.data.value || null,
          );
        },
      );
    }
  }, [isMember, fetchPlantMixTemperatureResponse, entityType, setValue]);

  const plantsToShow = useMemo(() => {
    const fromElement =
      +alternativePage === firstPage
        ? firstElement
        : (+alternativePage - onePage) * +alternativePerPage;
    return plantGroup?.notificationEntities?.slice(
      fromElement,
      fromElement + +alternativePerPage,
    );
  }, [alternativePage, alternativePerPage, plantGroup?.notificationEntities]);

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  const columns = useMemo(() => {
    return [
      {
        id: 'externalId',
        label: t(translations.notificationSettingsPage.id),
        alignment: CellAlignment.LEFT,
        size: isMember ? CellSize.SMALL : CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'name',
        label: t(translations.notificationSettingsPage.name, {
          entity: entityName,
        }),
        alignment: CellAlignment.LEFT,
        size: isMember ? CellSize.SMALL : CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [entityName, isMember, t]);

  const plantActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.plantGroupSection.removePlant),
        onClick: (row: any) => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Remove ${entityName} from group action`,
          );

          plantGroup &&
            selectedEntity?.notificationEntityGroupId &&
            removeNotificationEntityFromGroup({
              notificationEntityGroupId:
                +selectedEntity?.notificationEntityGroupId,
              internalIds: [row.internalId],
            })
              .then(() => {
                if (
                  plantsToShow?.length &&
                  plantsToShow.length <= minArrayLength &&
                  +page > firstPage
                )
                  history.push(
                    Routes.NotificationSettings.replace(
                      ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
                      `${organizationId}/${tab}/${page}/${perPage}/${
                        +alternativePage - onePage
                      }/${alternativePerPage}`,
                    ),
                  );
              })
              .then(() => {
                fetchPlantGroup();
              });
        },
      },
    ];
  }, [
    t,
    entityName,
    plantGroup,
    selectedEntity?.notificationEntityGroupId,
    removeNotificationEntityFromGroup,
    plantsToShow?.length,
    page,
    history,
    organizationId,
    tab,
    perPage,
    alternativePage,
    alternativePerPage,
    fetchPlantGroup,
  ]);

  const closeModalHandler = useCallback(() => {
    if (searchQuery) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    }

    setIsCreateGroupModalOpen(false);
    setIsPlantModalOpen(false);
    setIsRenameModalOpen(false);
  }, [history, searchQuery]);

  const groupActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.plantGroupSection.renameGroup),
        onClick: () => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Rename ${entityName} group action`,
          );

          setIsRenameModalOpen(true);
        },
      },
      {
        label: t(translations.notificationSettingsPage.removeGroup, {
          entity: entityName,
        }),
        onClick: () => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Remove ${entityName} group action`,
          );

          deleteNotificationEntityGroup().then(() => {
            resetSelectedEntityHandler(entityType);
            deleteGroupHandler();
          });
        },
      },
    ];
  }, [
    deleteGroupHandler,
    deleteNotificationEntityGroup,
    entityName,
    entityType,
    resetSelectedEntityHandler,
    t,
  ]);

  const addEntityClickHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationSettings,
      `Add ${entityName} to group action`,
    );

    fetchUnassignedNotificationEntities();
    setIsPlantModalOpen(true);
  }, [entityName, fetchUnassignedNotificationEntities]);

  const headerButton = useMemo(() => {
    return plantGroup ? (
      <div className={classes.button} onClick={addEntityClickHandler}>
        <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
          {t(translations.notificationSettingsPage.add, { entity: entityName })}
        </CustomTypography>
      </div>
    ) : undefined;
  }, [addEntityClickHandler, classes.button, entityName, plantGroup, t]);

  const createNotificationEntityGroupHandler = useCallback(
    (ids: string[], name?: string) => {
      if (name) {
        firebaseAnalytics.logPressEvent(
          Routes.NotificationSettings,
          `Create ${entityName} group`,
        );

        createNotificationEntityGroup({
          notificationEntityGroupName: name,
          internalIds: unassignedNotificationEntities
            ? unassignedNotificationEntities
                .filter(el => ids.includes(el.externalId))
                .map(el => {
                  return el.internalId;
                })
            : [],
        }).then((res: any) => {
          fetchNotificationEntities().then(() => {
            changeSelectedEntityHandler(
              entityType,
              res?.data?.notificationEntities[0],
            );
          });
        });
      }
    },
    [
      entityName,
      createNotificationEntityGroup,
      unassignedNotificationEntities,
      fetchNotificationEntities,
      changeSelectedEntityHandler,
      entityType,
    ],
  );

  const addEntityToGroupHandler = useCallback(
    (ids: string[]) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Add ${entityName}entity to group`,
      );

      plantGroup?.notificationEntityGroupId &&
        addNotificationEntitiesToGroup({
          notificationEntityGroupId: plantGroup.notificationEntityGroupId,
          internalIds: unassignedNotificationEntities
            ? unassignedNotificationEntities
                .filter(el => ids.includes(el.externalId))
                .map(el => {
                  return el.internalId;
                })
            : [],
        }).then(() => {
          fetchPlantGroup();
        });

      closeModalHandler();
    },
    [
      addNotificationEntitiesToGroup,
      closeModalHandler,
      entityName,
      fetchPlantGroup,
      plantGroup?.notificationEntityGroupId,
      unassignedNotificationEntities,
    ],
  );

  const updatePlantGroupHandler = useCallback(
    (data: any) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Update ${entityName} group name`,
      );

      selectedEntity?.notificationEntityGroupId &&
        updateNotificationEntityGroup({
          notificationEntityGroupId: +selectedEntity.notificationEntityGroupId,
          notificationEntityGroupName: data.name,
        }).then(() => fetchPlantGroup());

      closeModalHandler();
    },
    [
      closeModalHandler,
      entityName,
      fetchPlantGroup,
      selectedEntity?.notificationEntityGroupId,
      updateNotificationEntityGroup,
    ],
  );

  const createGroupHandler = useCallback(() => {
    fetchNotificationEntities();
    setIsCreateGroupModalOpen(true);
  }, [fetchNotificationEntities]);

  const handlePlantActionButton = () => {
    if (isEditing && mixTemperature) {
      changePlantMixTemperature({
        ...mixTemperature,
        value: getValues('value') || null,
      }).then(() => {
        setMixTemperature(curr =>
          curr ? { ...curr, value: getValues('value') || null } : curr,
        );
        setIsEditing(false);
      });
    } else {
      setIsEditing(true);
      setMixTemperature(getPlantMixTemperatureResponse?.data);
    }
  };

  return (
    <div className={classes.container}>
      {isRenameModalOpen && (
        <RenameModal
          header={t(translations.notificationSettingsPage.renameGroup, {
            entity: entityName,
          })}
          placeholder={t(translations.notificationSettingsPage.groupName)}
          label={t(translations.notificationSettingsPage.groupName)}
          onSubmit={updatePlantGroupHandler}
          isOpen={isRenameModalOpen}
          closeHandler={closeModalHandler}
          name={plantGroup?.notificationEntityGroupName || ''}
        />
      )}

      {(isAddPlantModalOpen || isCreateGroupModalOpen) && (
        <CustomTeamModal
          tableRowId="externalId"
          isOpen={isAddPlantModalOpen || isCreateGroupModalOpen}
          closeHandler={closeModalHandler}
          isLoading={isFetchingUnassignedNotificationEntities}
          header={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.createEntityGroup, {
                  entity: entityName,
                })
              : t(translations.notificationSettingsPage.addToGroup, {
                  entity: entityName,
                })
          }
          onAddItem={addEntityToGroupHandler}
          createItem={createNotificationEntityGroupHandler}
          withRemoveButton={false}
          withSearch={true}
          columns={columns}
          plants={unassignedNotificationEntities}
          emptyTableText={`${t(translations.notificationSettingsPage.noData, {
            entity: entityName,
          })}.`}
          withTableHeader={true}
          withTextField={isCreateGroupModalOpen}
          buttonText={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.createGroup)
              : t(translations.notificationSettingsPage.add, {
                  entity: entityName,
                })
          }
          textFieldHeader={t(translations.notificationSettingsPage.groupName)}
          searchHeader={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.add, {
                  entity: entityName,
                })
              : ''
          }
        />
      )}

      <div className={classes.groupsContainer}>
        {isSuperAdmin && !!selectedEntity && !isFetchingDosage ? (
          <div className={classes.headerContainer}>
            <Button variant="primaryProfile" onClick={addDosageHandler}>
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {assignedDosages?.data.length
                  ? t(translations.notificationSettingsPage.editDosage)
                  : t(translations.notificationSettingsPage.addDosage)}
              </CustomTypography>
            </Button>
          </div>
        ) : null}
        {!isMember ? (
          <div className={classes.headerContainer}>
            <Button variant="primaryProfile" onClick={createGroupHandler}>
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.notificationSettingsPage.createGroup)}
              </CustomTypography>
            </Button>
          </div>
        ) : null}
      </div>
      <div className={classes.groupsContainer} style={{ paddingTop: 0 }}>
        {!isMember &&
        !!selectedEntity &&
        !isFetchingPlantMixTemperatureResponse &&
        mixTemperature &&
        entityType === NotificationEntityType.PLANT ? (
          <div
            className={[
              classes.headerContainer,
              classes.headerContainerGap,
            ].join(' ')}
          >
            <NotificationPopover
              open={!!anchorEl}
              anchor={anchorEl}
              text={
                'Default concrete temperature is used to calculate Surface Evaporation when unloading if no other temperature measurement is available.'
              }
            />
            <div
              style={{
                display: 'flex',
                gap: '4px',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '6px',
                  justifyContent: 'center',
                }}
              >
                <div
                  className={classes.info}
                  onMouseEnter={e => setAnchorEl(e.currentTarget)}
                  onMouseLeave={() => setAnchorEl(null)}
                >
                  <InfoIcon
                    height={16}
                    width={16}
                    stroke={themeColors.mbsBlue}
                  />
                </div>
                <CustomTypography variant="bodySmall" color="accentDark">
                  {t(translations.notificationSettingsPage.defaultTemp)}
                </CustomTypography>
              </div>
              {isEditing ? (
                <div
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                >
                  <TextInput
                    name="value"
                    control={control}
                    error={!!errors.value}
                    helperText={errors.value?.message || ''}
                    placeholder={'Value' || ''}
                    backgroundColor="white"
                  />
                  <CustomTypography
                    variant="bodyRegular"
                    color="accentDark"
                    bold
                  >
                    {t(
                      translations.units[
                        mixTemperature.measurement as keyof typeof translations.units
                      ],
                    )}
                  </CustomTypography>
                </div>
              ) : (
                <CustomTypography
                  variant="bodySmall"
                  color="accentDark"
                  bold
                  style={{ marginLeft: '24px', fontWeight: 'bold' }}
                >
                  {mixTemperature.value === null
                    ? 'No defined value'
                    : `${mixTemperature.value} ${t(
                        translations.units[
                          mixTemperature.measurement as keyof typeof translations.units
                        ],
                      )}`}
                </CustomTypography>
              )}
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                type="submit"
                variant="primarySave"
                onClick={handlePlantActionButton}
                className={classes.actionButtons}
              >
                <CustomTypography variant="buttonTextSmall" bold color="white">
                  {t(
                    isEditing
                      ? translations.notificationSettingsPage.save
                      : translations.notificationSettingsPage.edit,
                  )}
                </CustomTypography>
              </Button>

              {isEditing && (
                <Button
                  type="submit"
                  variant="primaryProfile"
                  onClick={() => {
                    setIsEditing(false);
                    // @ts-ignore
                    setValue('value', mixTemperature.value);
                  }}
                  className={classes.actionButtons}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="mbsBlue"
                  >
                    {t(translations.notificationSettingsPage.cancel)}
                  </CustomTypography>
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>

      {selectedEntity?.notificationEntityGroupId || '' ? (
        <div className={classes.wrapper}>
          <SectionSpoiler
            header={plantGroup?.notificationEntityGroupName || ''}
            withIcons={false}
            actions={!isMember ? groupActions : undefined}
          >
            <Table
              withHover={true}
              withPagination={true}
              columns={columns}
              tableData={plantsToShow}
              withCheckbox={false}
              isLoading={isFetchingNotificationEntityGroup}
              actions={!isMember ? plantActions : undefined}
              headerButton={!isMember ? headerButton : undefined}
              emptyTableText={`${t(
                translations.notificationSettingsPage.noData,
                {
                  entity: entityName,
                },
              )}.`}
              baseURL={Routes.NotificationSettings.replace(
                ':organizationId/:tab',
                `${organizationId}/${tab}`,
              )}
              searchParams={searchParams()}
              rowsTotal={plantGroup?.notificationEntities?.length}
              paginationType={PaginationType.SECOND_LEVEL}
            />
          </SectionSpoiler>
        </div>
      ) : null}
    </div>
  );
};
